/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
import { StaticColors, THEME_SCOPES } from "./types.js";
import _ThemeLight from "./themes/default-light.json";
import _ThemeDark from "./themes/default-dark.json";
const ThemeLight = _ThemeLight;
const ThemeDark = _ThemeDark;
export function getPreviewColors(theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { base, navigationMenu, statusBar, list, editor } = theme.scopes;
    const { primary, success } = base;
    return {
        navigationMenu: {
            shade: deriveShadeColor(((_a = navigationMenu === null || navigationMenu === void 0 ? void 0 : navigationMenu.primary) === null || _a === void 0 ? void 0 : _a.accent) || primary.accent),
            accent: ((_b = navigationMenu === null || navigationMenu === void 0 ? void 0 : navigationMenu.primary) === null || _b === void 0 ? void 0 : _b.accent) || primary.accent,
            background: ((_c = navigationMenu === null || navigationMenu === void 0 ? void 0 : navigationMenu.primary) === null || _c === void 0 ? void 0 : _c.background) || primary.background,
            icon: ((_d = navigationMenu === null || navigationMenu === void 0 ? void 0 : navigationMenu.primary) === null || _d === void 0 ? void 0 : _d.icon) || primary.icon
        },
        statusBar: {
            paragraph: ((_e = statusBar === null || statusBar === void 0 ? void 0 : statusBar.primary) === null || _e === void 0 ? void 0 : _e.paragraph) || primary.paragraph,
            background: ((_f = statusBar === null || statusBar === void 0 ? void 0 : statusBar.primary) === null || _f === void 0 ? void 0 : _f.background) || primary.background,
            icon: ((_g = statusBar === null || statusBar === void 0 ? void 0 : statusBar.success) === null || _g === void 0 ? void 0 : _g.icon) || success.icon
        },
        editor: ((_h = editor === null || editor === void 0 ? void 0 : editor.primary) === null || _h === void 0 ? void 0 : _h.background) || primary.background,
        list: {
            heading: ((_j = list === null || list === void 0 ? void 0 : list.primary) === null || _j === void 0 ? void 0 : _j.heading) || primary.heading,
            background: ((_k = list === null || list === void 0 ? void 0 : list.primary) === null || _k === void 0 ? void 0 : _k.background) || primary.background,
            accent: ((_l = list === null || list === void 0 ? void 0 : list.primary) === null || _l === void 0 ? void 0 : _l.accent) || primary.accent,
            accentForeground: ((_m = list === null || list === void 0 ? void 0 : list.primary) === null || _m === void 0 ? void 0 : _m.accentForeground) || primary.accentForeground
        },
        border: primary.border,
        paragraph: primary.paragraph,
        background: primary.background,
        accent: primary.accent,
        accentForeground: primary.accentForeground
    };
}
export function themeToCSS(theme) {
    const css = [];
    for (const scopeKey of THEME_SCOPES) {
        const scope = theme.scopes[scopeKey] || {};
        const variants = buildVariants(scopeKey, theme, scope);
        let scopeCss = `.theme-scope-${scopeKey} {\n\t`;
        for (const variantKey in variants) {
            const variant = variants[variantKey];
            if (!variant)
                continue;
            css.push(`.theme-scope-${scopeKey}-${variantKey} {
          ${colorsToCSSVariables(variant, variantKey)}
        }`);
            scopeCss += colorsToCSSVariables(variant, variantKey);
            scopeCss += "\n\n";
        }
        scopeCss += "}";
        css.push(scopeCss);
    }
    return css.join("\n\n");
}
export function buildVariants(scope, theme, themeScope) {
    const defaultTheme = theme.colorScheme === "dark" ? ThemeDark : ThemeLight;
    const defaultThemeBase = defaultTheme.scopes.base;
    const defaultThemeScope = defaultTheme.scopes[scope] || {};
    function getColor(variant, color) {
        var _a, _b, _c, _d;
        return (((_a = themeScope[variant]) === null || _a === void 0 ? void 0 : _a[color]) ||
            ((_b = theme.scopes.base[variant]) === null || _b === void 0 ? void 0 : _b[color]) ||
            ((_c = defaultThemeScope[variant]) === null || _c === void 0 ? void 0 : _c[color]) ||
            ((_d = defaultThemeBase[variant]) === null || _d === void 0 ? void 0 : _d[color]));
    }
    const variant = Object.assign(Object.assign(Object.assign({}, defaultThemeBase), defaultThemeScope), { primary: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultThemeBase.primary), defaultThemeScope.primary), theme.scopes.base.primary), themeScope.primary), { shade: deriveShadeColor(getColor("primary", "accent")) }), secondary: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultThemeBase.secondary), defaultThemeScope.secondary), theme.scopes.base.secondary), themeScope.secondary), { shade: deriveShadeColor(getColor("secondary", "accent")) }), disabled: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultThemeBase.disabled), defaultThemeScope.disabled), theme.scopes.base.disabled), themeScope.disabled), { shade: deriveShadeColor(getColor("disabled", "accent")) }), error: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultThemeBase.error), defaultThemeScope.error), theme.scopes.base.error), themeScope.error), { shade: deriveShadeColor(getColor("error", "accent")) }), success: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultThemeBase.success), defaultThemeScope.success), theme.scopes.base.success), themeScope.success), { shade: deriveShadeColor(getColor("success", "accent")) }), selected: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultThemeBase.selected), defaultThemeScope.selected), theme.scopes.base.selected), themeScope.selected), { shade: deriveShadeColor(getColor("selected", "accent")) }), static: StaticColors });
    return variant;
}
export function colorsToCSSVariables(colors, variantKey) {
    let root = "";
    const suffix = !variantKey || variantKey === "primary" ? "" : `-${variantKey}`;
    for (const color in colors) {
        root += `--${color}${suffix}: ${colors[color]};\n`;
    }
    return root;
}
function deriveShadeColor(color) {
    return changeColorAlpha(color, 0.1);
}
function changeColorAlpha(color, opacity) {
    //if it has an alpha, remove it
    if (color.length > 7)
        color = color.substring(0, color.length - 2);
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    let opacityHex = _opacity.toString(16).toUpperCase();
    // opacities near 0 need a trailing 0
    if (opacityHex.length == 1)
        opacityHex = "0" + opacityHex;
    return color + opacityHex;
}
